<template>
  <div>
    <ul v-if="errors" class="errorMessage">
      <li class="error" v-for="error in errors" :key="error">{{ error }}</li>
    </ul>
    <h2>Make your reservation</h2>
    <p>Please review your contact information and update it, if needed. Then reserve your spot.</p>
    <MemberContactDetails :member="getMember()" />
    <button class="purchase" @click="makeReservation">Reserve my spot</button>
  </div>
</template>

<script>
import { axiosInstance } from '@/library'
import MemberContactDetails from '../members/MemberContactDetails.vue'
import member from '@/mixins/member'

export default {
  name: 'RegistrationForm',
  components: { MemberContactDetails },
  mixins: [member],

  data: () => ({
    errors: null,
  }),

  beforeMount () {
    this.loadMember(this.getMemberPublicId())
  },

  methods: {
    makeReservation () {
      const projectId = this.$route.params.id;
      axiosInstance.post(`${window.VUE_APP_GRANJA}/api/v1/community/projects/${projectId}/reserve`,
        {
          memberPublicId: this.getMemberPublicId()
        },
        {
          headers: {
            "Content-Type": "application/json"
          }
        })
        .then(() => {
          this.errors = [];
          this.$router.push({ name: 'members' })
        })
        .catch((exception) => {
          this.errors = [];
          this.errors.push(exception);
        });
    }
  },
}
</script>
