<template>
  <div class="container two-sided">
    <div style="flex-shrink: 0.7;">
      <h1>Register to participate in a project</h1>
      <p>You are registering to participate in the following project as a <strong>volunteer</strong>:</p>
      <div v-if="project" class="project-overview">
        <div>
          <div class="project-name">{{ project.name }}</div>
          <p class="project-description">{{ project.description }}</p>
        </div>
        <table class="project-details">
          <tr>
            <td class="label">Earliest start date:</td>
            <td>{{ formatMonthYear(project.plannedStartDate) }}</td>
          </tr>
          <tr>
            <td class="label">Estimated duration:</td>
            <td>{{ formatDuration(project.plannedDuration) }}</td>
          </tr>
          <tr>
            <td class="label">Maximum participants:</td>
            <td>{{ project.maxNumberOfParticipants }} persons and we have {{ project.participationSpotsLeft }} spots
              left.</td>
          </tr>
        </table>
        <h2>When does the project happen</h2>
        <p>The exact dates for the project will be agreed upon amongst registered participants. You
          can also participate in multiple projects over a longer period of time.</p>
        <RegistrationForm :projectId="$route.params.id" />
      </div>
    </div>
    <div>
      <h2>What to expect</h2>
      <p>The project has been planned in advance an online with all participants and voluntary advisers.
        Required material and tools will be on site. Bring your enthusiasm.</p>
      <dl>
        <dt>Welcome and farewell dinners</dt>
        <dd>On the day of arrival we will take the whole group out for dinner at a good local restaurant.
          Everybody should meet their mates for the project and we will also do a briefing. The
          evening before your departure we will also take you out for dinner and drinks to express our gratitude.</dd>

        <dt>Accomodation and food</dt>
        <dd>You will sleep in the old farmhouse "cortijo" in two rooms that sleep up to 4 people each. Showers and
          compost
          toilets are available and also a lockable closet for your clothes and other items.</dd>
        <dd>All the food and beverages are provided. During the briefing at the beginning we compile a shopping list
          together. You will have to organize the cooking as a group.</dd>
        <dd>We have WiFi and you can charge your devices as well.</dd>

        <dt>Work</dt>
        <dd>We try to work on the project for about five hours per day and under guidance of a Granja Caimito
          associate.
          In Summer we start early to avoid the heat. After project work the day is yours.</dd>
        <dd>You should be able to perform light to medium physical tasks over the day as outlined in the project's
          description. Required protective gear like gloves and other items are provided.</dd>

        <dt>Transportation, leisure activities</dt>
        <dd>On the days of arrival and departure we will provide group transportation from/to the Cordoba train
          station
          and the Seville airport.</dd>
        <dd>We do not have a "courtesy car" but will provide group transportation for planned events.</dd>
        <dd>We can organize leisure activities for the whole group or provide something like riding lessons with the
          horses we have.</dd>
      </dl>

      <h2>What to bring</h2>
      <ul>
        <li>Sleeping bag suitable for the season</li>
        <li>Items for your personal hygiene</li>
        <li>Robust <strong>work cloths and solid shoes</strong> (possibly metal toe protection)</li>
        <li>Health insurance card</li>
        <li>In summer, sunscreen and a hat might be a good idea</li>
      </ul>

      <h2>Additional notes</h2>
      <h3>Dietary requirements, alergies</h3>
      <p>As this is a group event with limited food options it might be difficult to accomodate special dietary
        requirements or to protect people who have allergies.</p>

      <h3>Do I need a work permit?</h3>
      <p>No, you don't need that. You will not be working for renumeration but as a volunteer. But it is important to
        have health insurance in case something unfortunate might happen. That means: people from outside the European
        Union can also participate as it is a working vacation.</p>
    </div>
  </div>
</template>

<style scoped>
.bold,
.label {
  font-weight: bold;
}

DIV.project-overview {
  margin-left: 1em;
}

P.project-description {
  font-size: smaller;
  margin-left: 10px;
}

TABLE.project-details {
  margin-top: 1em;
}

.project-name {
  font-weight: bold;
}
</style>

<script>
import { axiosInstance, formatDuration as helperFormatDuration, formatMonthYear as helperFormatMonthYear } from '@/library'
import RegistrationForm from './RegistrationForm.vue'

export default {
  name: "CommunityRegister",
  components: {
    RegistrationForm
  },

  data: () => ({
    project: undefined,
    error: null
  }),

  beforeMount () {
    this.loadProject()
  },

  methods: {
    formatDuration (value) {
      return helperFormatDuration(value)
    },

    formatMonthYear (value) {
      return helperFormatMonthYear(value)
    },

    loadProject () {
      const projectId = this.$route.params.id

      axiosInstance.get(`${window.VUE_APP_GRANJA}/api/v1/community/projects/${projectId}`)
        .then(response => {
          if (response.status === 200) {
            this.project = response.data
          }
        })
        .catch((exception) => {
          this.error = exception
        })
    }
  }
}
</script>
